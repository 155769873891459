var _paq = (window._paq = window._paq || []);
function statistic(path, siteId) {
  (function () {
    const u = "https://eurofinsbiologiemedicale.matomo.cloud/";
    // Remove the script added before
    const d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    const sSrc = s.getAttribute("src");
    if (
      sSrc ===
      "//cdn.matomo.cloud/eurofinsbiologiemedicale.matomo.cloud/matomo.js"
    ) {
      s.remove();
    }
    // Set variables
    
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

    _paq.push(["setDocumentTitle", path]);
    _paq.push(["setCustomUrl", path]);
    _paq.push(["disableCookies"]);
    _paq.push(["disableCookies", true]);
    _paq.push(["trackPageView", path]);
    _paq.push(["enableLinkTracking"]);
    (function () {
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", siteId]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.src =
        "//cdn.matomo.cloud/eurofinsbiologiemedicale.matomo.cloud/matomo.js";
      s.parentNode.insertBefore(g, s);
    })();
  })();
}
